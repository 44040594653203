import React, {useRef, useEffect, createRef} from "react";
import { Seo } from "../../components/Seo";
import {graphql} from 'gatsby';
import Carousel from "../../components/Projects/Carousel";
import { StaticImage } from "gatsby-plugin-image";
import ContactForm from "../../components/ContactForm";

const PPEProject = ({data}) => {
  const textRef = useRef([]);
  const imageRef = useRef([]);
  for(let i = 0; i < 3; i++) {
    textRef.current[i] = createRef();
    imageRef.current[i] = createRef();
  }

  const resizeSection = () => {
    for(const[k,i] of Object.entries(textRef.current)) {
      if(i.current || false) {
        let mheight = i.current.offsetHeight + 40;
        
        imageRef.current[k].current.style.maxHeight = `${mheight}px`;
      }
    }
  }

  useEffect(() => {
    window.addEventListener('resize', resizeSection);
    resizeSection();
  }, []);

  const projects = data.allPrismicMixedContentProject.edges.map((i,k) => ({
    to: i.node.uid,
    name: i.node.data.title,
    description: i.node.data.description,
    src: i.node.data.cover_image.url
  }))
  return (
    <main>
      <section>
        <div className="grid lg:grid-cols-2 items-start">
          <div ref={textRef.current[0]}
            className="py-24 px-16 xl:px-32">
            <h1 className="font-black text-4xl lg:text-5xl mb-12">
              PPE
            </h1>
            <p className="text-xl lg:text-2xl mb-8">
              Through some of the most difficult international trade conditions in history, Sourci was able to provide transparency and complete supply chain control for corporates and governments alike.
            </p>
            <p className="text-xl lg:text-2xl mb-8">
              Rewind back to March 2020, cities and entire countries across the world are shutting down, going into lockdowns, due to a highly contagious virus spreading like wildfire. Their best defence against this virus is isolating citizens, and equipping their people with critical Personal Protective Equipment (PPE).
            </p>
            <p className="text-xl lg:text-2xl mb-8">
              Sourci played a vital role in sourcing and safely supplying tens of millions of units of critical PPE to both the Australian Federal Government and the National Health Service (NHS) in the United Kingdom.
            </p>
          </div>
          <div className="flex h-full"
            ref={imageRef.current[0]}>
              <StaticImage src="../../images/ppe-1.jpg"
                alt=""
                className="object-cover min-w-[100%]" />
          </div>
        </div>
      </section>

      <section className="bg-[#f8f8f8] py-24">
        <div className="xl:container grid-cols-1 lg:grid-cols-4 mx-auto px-6 grid text-center gap-x-12 gap-y-12">
          <div>
            <p className="font-black text-5xl lg:text-6xl mb-3">
              <span>100%</span>
            </p>
            <p className="font-black text-xl">PRODUCT CONFORMITY</p>
          </div>
          <div>
            <p className="font-black text-5xl lg:text-6xl mb-3">
              <span>40M+</span>
            </p>
            <p className="font-black text-xl">UNITS OF PPE</p>
          </div>
          <div>
            <p className="font-black text-5xl lg:text-6xl mb-3">
              <span>AU & UK</span>
            </p>
            <p className="font-black text-xl">GOVERNMENTS SERVICED</p>
          </div>
          <div>
            <p className="font-black text-5xl lg:text-6xl mb-3">
              <span>100+</span>
            </p>
            <p className="font-black text-xl">HOSPITALS & MEDICAL FACILITIES REPLENISHED</p>
          </div>
        </div>
      </section>

      <section>
        <div className="grid lg:grid-cols-2 items-start">
          <div className="flex h-full"
            ref={imageRef.current[1]}>
              <StaticImage src="../../images/ppe-2-2175w.jpg"
                alt=""
                className="object-cover min-w-[100%]" />
          </div>
          <div ref={textRef.current[1]}
            className="py-24 px-16 xl:px-32">
            <p className="text-xl lg:text-2xl mb-8">
              With all international travel completely written off, there was no way for governments or even private industry to safely vet, qualify, setup or manage a new supply chain. The global demand for PPE resulted in a very dangerous playing field for purchasers, and a very lucrative market for opportunistic factories across the Asian continent.
            </p>
            <p className="text-xl lg:text-2xl mb-8">
              Who better to engage to setup & oversee such a critical supply chain remotely than an Australian owned & managed global supply chain company, that had just spent the past 3 years building our trustworthy internal teams across China, India, and Vietnam? Nobody. Period.
            </p>
            <p className="text-xl lg:text-2xl mb-8">
              With all of the established, name brand PPE manufacturers annual production for 2020 being snapped up before most of the worlds governments had approved and arranged budgets for PPE in March 2020, it left governments to purchase from the 100’s of brand new overnight converted factories who’d started making PPE only that month.
            </p>
            <p className="text-xl lg:text-2xl mb-8">
              Most procurement professionals just wanted to see an approved universal certificate for the masks, and that would be sufficient for them to place purchase orders in the tens of millions of USD. Be it a “CE” or “FDA” certification, they would simply tick their box and be filled with confidence. This was a nasty flaw in the process, as we very quickly learnt, and solved for our clients.
            </p>
            <p className="text-xl lg:text-2xl mb-8">
              You see, a certification for masks can be issued by testing 1 batch of good quality masks, but what happens the next week when the factory purchases a much cheaper, inferior Meltblown cloth material? (That’s the layer of fabric inside a mask that actually prevents bacteria spreading, so it is extremely important to the function).
            </p>
            <p className="text-xl lg:text-2xl mb-8">
              An FDA certification lasts for 12 months, passing with flying colours from just one lab test. This creates a very risky environment, for inferior products being substituted and being sold using the “perfectly valid and current” FDA certificate.
            </p>
          </div>
        </div>
      </section>

      <section className="bg-[#f8f8f8] py-24">
        <div className="xl:container mx-auto px-6 text-center">
          <h3 className="font-black text-5xl lg:text-6xl">
            Sourci puts product sourcing wizardry at the heart of your team. Push the button. <span className="block text-sourci-pink">Make amazing happen.</span>
          </h3>
        </div>
      </section>

      <section>
        <div className="grid lg:grid-cols-2 items-start">
          <div ref={textRef.current[2]}
            className="py-24 px-16 xl:px-32">
            <h3 className="font-black text-2xl lg:text-3xl mb-12">
              Sourci's Solution?
            </h3>
            <p className="text-xl lg:text-2xl mb-8">
              Rapidly visiting dozens of factories, flying our team members across the country, back to back meetings and factory visits in the middle of the night, full production auditing, cross-calculating supply capability, full production and packing supervision, highly sophisticated Quality Control (QC), raw materials removal and independent testing, local government official backing, and a team of 30+ managing procurement exclusively for these government contracts day & night.
            </p>
            <p className="text-xl lg:text-2xl mb-8">
              Sourci’s expert teams were able to use strong influence to secure quality factory production allocation, when much of the world couldn’t. Our teams were able to devise an extremely safe and sophisticated quality control system to guarantee that the millions of masks that Sourci supplied to governments were genuinely exceptional in form, function, and bacterial/particle filtration efficiency (BFE & PFE {'>'}99%).
            </p>
            <p className="text-xl lg:text-2xl mb-8">
              We encountered dozens of “certified” factories producing extremely non-compliant PPE, that unfortunately other corporations and governments were purchasing by simply not knowing enough or having enough control over their international supply chains.
            </p>
            <p className="text-xl lg:text-2xl mb-8">
              Sourci are proud to have delivered these contracts with extreme efficiency, consistency, quality products, and without a single customs or importing delay. Pure professional efficiency by Australia’s ‘up & coming’ privately owned sourcing company.
            </p>
          </div>
          <div className="flex h-full"
            ref={imageRef.current[2]}>
              <StaticImage src="../../images/ppe-3-2175w.jpg"
                alt=""
                className="object-cover min-w-[100%]" />
          </div>
        </div>
      </section>

      <section className="bg-[#f8f8f8] py-32">
        <div className="xl:container lg:grid lg:grid-cols-2 mx-auto px-6">
          <div className="text-black">
            <p className="text-4xl md:text-6xl font-black mb-8">The time<br /> is now.</p>
            <p className="text-black text-4xl mb-8 font-black">Let's make amazing<br /> happen today.</p>
            <p className="text-3xl mb-2">
              <a href="tel:+611300768724"
                className="hover:underline">1300 SOURCI</a>
            </p>
            <p className="text-3xl mb-2">
              <a href="mailto:hello@sourci.com.au"
                className="hover:underline">hello@sourci.com.au</a>
            </p>
          </div>
          <div className="mt-12 lg:mt-0">
            <ContactForm bg="light"
              location="Case Study - PPE (Personal Protective Equipment)" />
          </div>
        </div>
      </section>
      <Carousel projects={projects}
        project="ppe"
        title="Check out our other Projects" />
    </main>
  )
}

export const query = graphql`
  query {
    allPrismicMixedContentProject {
      edges {
        node {
          data {
            title
            description
            cover_image {
              url
            }
          }
          uid
        }
      }
    }
  }
` 

export default PPEProject;

export function Head() {
  return (
    <Seo title="PPE (Personal Protective Equipment) | Our Projects | Sourci"
      description="Discover how Sourci provided transparency and complete supply chain control through the most difficult international trade conditions in history."
      canonical="/projects/ppe" />
  )
}